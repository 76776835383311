import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { ReactComponent as Check } from '../../../Assets/icon-checked-flat.svg';
import { ReactComponent as Box } from '../../../Assets/icon-unchecked-flat.svg';
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { selectUserSubscriptions } from "../../../AppSlice";
import { User } from "../../../global/user";

const TableRowContainer = (props) => {
    const { item, page, index, editable, selectedPolicies, handlePolicyCheck } = props
    const classes = useStyles()

    const subscriptions = useSelector(selectUserSubscriptions)

    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;
    const isFreeSubcriptionPlan = isBroker && !subscriptions.find(({ name }) => name === "basic")

    return (
    <TableRow className={clsx(classes.tableBody, index > 4 && isFreeSubcriptionPlan ? classes.tableRowBlurry : '', selectedPolicies[item.id] || selectedPolicies["all"] ? classes.tableRowSelected : '') } key={`policy-table-row-${page}-${index}`}>
        {editable && <TableCell className={classes.checkContainer}>
            <div className={classes.checkContent}>
                {selectedPolicies["all"] 
                ? <Checkbox checked checkedIcon={<Check className={classes.checkedIcon}/>} icon={<Box className={classes.checkedIcon}/>}/>
                : <Checkbox key={item.id} onChange={(e) => handlePolicyCheck(e, item.id)} checkedIcon={<Check className={classes.checkedIcon}/>} icon={<Box className={classes.checkedIcon}/>}/>
                }
            </div>
        </TableCell>
        }
        {props.children}
    </TableRow>  
    )
}

export default TableRowContainer