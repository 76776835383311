import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, styled, TableCell } from "@material-ui/core";
import moment from "moment";

// Components
import { basicButtonStyles } from "../../../Components/ui/buttons";
import PriorityIndicator from "../PriorityIndicator/PriorityIndicator";
import CreateCampaignsDialog from "../CreateCampaignsDialog/CreateCampaignsDialog";
import UnregisteredPopup from "../../../Components/Popups/UnregisteredPopup/UnregisteredPopup";
import ProspectsContainer from "../../../Components/Book/ProspectsContainer/ProspectsContainer";
import RiderStatusIndicator from "../RiderStatusIndicator/RiderStatusIndicator";

// Redux
import { resetFilters, resetLapseFilters, resetUIFilters, selectHeaders, selectProductsData, selectProspectsData, selectProspectsFilters, selectQueryFilters, setProspectsData, setProspectsDataAE, setProspectsDataBroker, setQueryFilters } from "../BookAnalysisSlice";
import { selectColumns, selectUserCompany } from "../../../AppSlice";
import BookAnalysisService from "../BookAnalysisService";

// Services
import { getCountryCurrencySymbol, nFormatter, amplitudeService, buildQueryString, getPriorityFromRank } from "../../../global/helpers";

import { useStyles } from "../../../Components/Book/ProspectsContainer/styles";
import { frozenGridFieldsAnnuities, headerKeysAnnuities, headerKeysLife, prospectsAnnuitiesTableHeaders, prospectsAnnuitiesDataKeys, prospectsLifeTableHeaders, prospectsLifeDataKeys, frozenGridFieldsLife, frozenGridColumnsAnnuities, frozenGridColumnsLife, prospectsBrokerTableHeaders, headerKeysBroker, frozenGridColumnsBroker, prospectsBrokerDataKeys, frozenGridColumnsPositions, frozenGridColumnsPositionsBroker, frozenGridFieldsBroker, priorityColors } from "../../../global/constants";
import StoryIcon from "../../../Components/Book/StoryIcon/StoryIcon";
import { setSlice } from "../../../Components/Tables/TableSlice";
import { CsvBuilder } from "filefy";
import { setAvg, setFiltersConfig } from "../../../Components/Run/RunSlice";
import { User } from "../../../global/user";
import PolicyPopupBroker from "../../../Components/Popups/PolicyPopup/PolicyPopupBroker";
import PriorityTag from "./PriorityTag/PriorityTag";
import { InfoTooltip } from "../../../Components/ui/tooltips";

export const UIProspectsButton = styled(Button)({
    ...basicButtonStyles,
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "19px",
    padding: '26px 0',
    width: '248px',
    height: 'auto',
    borderRadius: '8px',
    backgroundColor: '#0BCB47',
    borderColor: '#FFF',
    color: '#FFF',
    '&:hover': {
      opacity: '0.9',
      backgroundColor: '#0BCB47',
      borderColor: '#FFF',
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      opacity: '0.25',
      borderColor: '#FFF',
      boxShadow: 'none',
      color: '#FFFFFF'
    },
  });

const ApiService = new BookAnalysisService()

const ProspectsAE = ({ projectType, annuities = false }) => {
    const currency = getCountryCurrencySymbol()
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const rootRef = useRef(null)
    const searchRef = useRef(null)
    const summaryData = useSelector(selectProductsData)
    const headersData = useSelector(selectHeaders)
    const prospectsData = useSelector(selectProspectsData)
    const prospectsFilters = useSelector(selectProspectsFilters)
    const queryFilters = useSelector(selectQueryFilters)
    const columns = useSelector(selectColumns)
    const user = useSelector(selectUserCompany)
    const isBroker = localStorage.getItem("accountType") === "broker" && User().isHideOnProd
    const demo = history.location.search.includes("demo") || user === "platform-anonym" 
    const [ openCampaignsDialog, setOpenCampaignsDialog ] = useState(false)
    const [ openRegisterDialog, setOpenRegisterDialog ] = useState(false)
    const [ selectedPolicy, setSelectedPolicy ] = useState(null)
    const [ stories, setStrories ] = useState([])
    const [ notes, setNotes ] = useState([])
    const [ statusHistory, setStatusHistory ] = useState([])
    const [ page, setPage ] = useState(0)
    const [ rowsPerPage, setRowsPerPage ] = useState(10)
    const [ sort, setSort ] = useState(isBroker ? "rank" : "lapse_rank")
    const [ search, setSearch ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const [ clear, setClear ] = useState(false)
    const [maxRank, setMaxRank] = useState(null)
    const sortKeys = prospectsData.sortKeys
    const prospectsDataKeys = prospectsData.dataKeys
    const frozenGridFields = annuities ? frozenGridFieldsAnnuities 
    : isBroker ? frozenGridFieldsBroker
    : frozenGridFieldsLife
    const frozenGridColumns = annuities ? frozenGridColumnsAnnuities
    : isBroker ? frozenGridColumnsBroker
    : frozenGridColumnsLife
    const frozenGridColumnsPositionsSelected = isBroker ? frozenGridColumnsPositionsBroker
    : frozenGridColumnsPositions
    
    const loaded = !loading  && (summaryData?.lapse_runs?.length || summaryData?.ui_runs?.length || isBroker) ? true : false
    const filteredFrozenFields = frozenGridFields.filter((field) => columns[prospectsDataKeys[field]])

    const handleCloseCampaignsDialog = () => {
      setOpenCampaignsDialog(false)
    }

    const handleChangeRowsPerPage = (value) => {
      setPage(0);
      dispatch(setSlice(0));
      setRowsPerPage(parseInt(value));
    };

    const handleChangePage = (e = null, newPage) => {
      setPage(newPage);
      amplitudeService._throwEvent("TABLE_CHANGE_PAGE", {'title': "Prospects AE", 'value': newPage, 'page': window.location.pathname})
    };

    const handleSortClick = (item) => {
      if (item) {
          const orderBy = `${sort.includes("-") ? "" : "-"}${sortKeys[item]}`
          setSort(orderBy)
      }
    }

    const handleSearch = (e) => {
      if (searchRef.current) {
        clearTimeout(searchRef.current)
      }
      searchRef.current = setTimeout(() => {
        setSearch(e.target.value)
      }, 2000)
    }

    const handleOpenPolicyPopup = (policy, id, story_type) => {
      if (isBroker) {
      ApiService._getProspectNote(
        id,
        (response) => {
            setSelectedPolicy(policy)
            setNotes(response.data.notes[0])
        },
        (error) => { 
          setSelectedPolicy(policy)
          setNotes([])
        }
      )
      ApiService._getProspectStatusHistory(
        id,
        (response) => {
          setStatusHistory(response.data.history?.reverse())
        },
        (error) => { 
          setStatusHistory([])
        }
      )
      ApiService._putOpenAIPolicyStory(
        id,
        story_type,
        (response) => {
          const data = []
          const string = response.data?.gen_ai_upsell_story
            ?.replaceAll("**", "*")
            .split("*")
          string
            ?.map((item, idx) => {
              if (idx%2 === 0 && idx !== 0) data.push({ title: string[idx-1], text: item.replace(":", "") })
            })
          setStrories(data.length ? data : null);
        },
        (error) => {
          setStrories([])
        }
      )
      } else {
        setSelectedPolicy(policy)
        ApiService._putOpenAIStoryByPolicy(
          policy,
          (response) => {
            const data = []
            const string = response.data?.gen_ai_upsell_story
              ?.replaceAll("**", "*")
              .split("*")
            string
              ?.map((item, idx) => {
                if (idx%2 === 0 && idx !== 0) data.push({ title: string[idx-1], text: item.replace(":", "") })
              })
            setStrories(data.length ? data : null);
          },
          (error) => {
            setStrories(null)
          }
        )
      }
    }

    const resetNotes = (policy) => {
      ApiService._getProspectNote(
        policy,
        (response) => {
            setNotes(response.data.notes[0])
        },
        (error) => { 
          console.log(error);
        }
    )
    }
  
    const resetHistory = (policy) => {
      ApiService._getProspectStatusHistory(
        policy,
        (response) => {
            setStatusHistory(response.data.history?.reverse())
        },
        (error) => { 
          console.log(error);
        }
      )
    }

    const handleEditPolicy = (id, data, callback) => {
      ApiService._patchRecommendation(
        id,
        data,
        (response) => {
            callback()
            setNotes(response.data.notes[0])
        },
        (error) => { 
          callback()
          console.log(error);
        }
    )
    }

    const renderRow = (rowData, headers, onClick) => {
      return headers
        .filter((header) => columns[header])
        .map((header, index) => {
          const key = sortKeys[header]
          return (
          <TableCell component="th" scope="row" key={index} className={filteredFrozenFields.includes(key) ? classes.sticky : ''} style={key === "policy" ? {
            fontWeight: 700,
            lineHeight: "16px",
            color: "#363ED3",
            cursor: "pointer",
            } 
            : filteredFrozenFields.includes(key) ? {
              right: frozenGridColumnsPositionsSelected[(filteredFrozenFields.length-1) - filteredFrozenFields.indexOf(key)],  
              boxShadow: (filteredFrozenFields.indexOf(key) === 0 || filteredFrozenFields.length === 1) ? "-4px 4px 5px #EEF0F450" : "none", 
            }
            : {}
          }
            onClick={() => onClick(rowData)}>
            {
              ["RATIO_WITHS_LAG", "SUB_ACTIVITY", "annualized_premium_sensitivity_NP"].includes(key) ? <PriorityIndicator priority={isNaN(rowData[key]) ? rowData[key]?.toLowerCase() : rowData[key]}/>
              : key === "libr_status" ? <RiderStatusIndicator status={rowData[key]?.toLowerCase()}/>
              : ["INCOME_ACOUNT_LOSS", "CONTRACT_LOSS"].includes(key) ? rowData[key] !== undefined && rowData[key] !== "null" ? `${rowData[key]}%` : "N/A"
              : ["agent_terminated"].includes(key) ? rowData[key] !== undefined && rowData[key] !== "null" ? `${rowData[key]}` : "N/A"
              : ["issue_date", "SURRCHRG_END_DATE_LAG", "dob"].includes(key) ? moment(rowData[key]).format("MM/DD/YYYY")
              : ["label", "annualized_premium_sensitivity_DML"].includes(key) ? rowData[key] ? <PriorityTag value={rowData[key]}/> : "N/A"
              : ["rank"].includes(key) ? rowData[key] ? <PriorityTag value={getPriorityFromRank(rowData[key], maxRank)}/> : "N/A"
              : ["policy", "servicing_agent_deid", "AGENT_ID_LAG"].includes(key) || key === "" ? rowData[key]?.length > 10 ? rowData[key].slice(0, 9)+"..." : rowData[key]
              : ["age_at_issue", "age", "YRS_REMAIN_SC", "lapse_rank", "zip_code"].includes(key) || key === "" ? rowData[key]? rowData[key] : "N/A" 
              : ["story", "story_lapse"].includes(key) ? rowData[key]? 
              <InfoTooltip title={rowData[key]}>
                <Grid item style={{ display: "flex"}} alignItems="center">
                  <div style={{ minWidth: 20, marginRight: 8 }}><StoryIcon storyType={rowData["model_type"]}/></div>
                  <div>{rowData[key]?.length > 25 ? rowData[key].slice(0, 24).trim()+"..." : rowData[key]}</div>
                </Grid>
              </InfoTooltip>
               : "N/A" 
              : !isNaN(rowData[key]) ? rowData[key] ? rowData[key] > 9999 ? nFormatter(rowData[key], 2, currency) : `$${Math.floor(rowData[key])}` : "N/A" 
              : rowData[key] ? rowData[key] : "N/A" 
            }
          </TableCell>
        )
      })
    }

    const handleResetFilters = () => {
      setClear(true)
      dispatch(resetFilters())
    }

    const handleResetLapseFilters = () => {
      setClear(true)
      dispatch(resetLapseFilters())
    }

    const handleResetUIFilters = () => {
      setClear(true)
      dispatch(resetUIFilters())
    }
    
    const exporttocsv = (id, name, filter = "") => {
      if (isBroker) {
        ApiService._getExportCsv(buildQueryString(filter), (response) => {
          if (response.data.length > 0) {
            const data = response.data.split('\n')
            const csvHeaders = data.shift().split(',')
            const csvData = data.map((row) => {
              const rowValue = row.split(",")
              return rowValue
            })
            let fileName = `magnet_${headersData.effective_date}.csv`
            let csvBuilder = new CsvBuilder(fileName)
              .setColumns(csvHeaders)
              .addRows(csvData)
            csvBuilder.exportFile()
          }
        }, (error) => console.log(error))
      } else {
        ApiService._postExportCsv([id], {
          filters: filter ? filter : {}
        }, (response) => {
          if (response.data.length > 0) {
            const data = response.data.split('\n')
            const csvHeaders = data.shift().split(',')
            const csvData = data.map((row) => {
              const rowValue = row.split(",")
              return rowValue
            })
            let fileName = `${name}_${headersData.effective_date}.csv`
            let csvBuilder = new CsvBuilder(fileName)
              .setColumns(csvHeaders)
              .addRows(csvData)
            csvBuilder.exportFile()
          }
        }, (error) => console.log(error))

      }
    }

    const onPageChange = useCallback(() => {
        if (prospectsData?.prospects.length && isBroker) {
        setLoading(true)
        ApiService._getRecommendations((response) => {
          setLoading(false)
          dispatch(setProspectsDataBroker(response.data))
        }, 
        () => {setLoading(false)},
        buildQueryString(queryFilters.lapse), prospectsFilters.storyType, page, rowsPerPage, sort, search)
      } else if (prospectsData?.prospects.length && (summaryData?.lapse_runs?.length || summaryData?.ui_runs?.length)) {
        setLoading(true)
        ApiService._postProspectsData(
          demo,
          [
            ...summaryData?.lapse_runs.map(({ run_id }) => run_id),
            ...summaryData?.ui_runs.map(({ run_id }) => run_id),
            ...summaryData?.cluster_runs.map(({ run_id }) => run_id)
          ],
          {
            lapse_filters: {
              "precision": [
                  {
                      "operator": "precision",
                      "value": "medium"
                  }
              ],
              ...queryFilters.lapse
            },
            ui_filters: { ...queryFilters.ui }
        }, (response) => {
          annuities ? dispatch(setProspectsDataAE(response.data)) : dispatch(setProspectsData(response.data))
          setLoading(false)
        }, 
        (error) => { console.log(error); setLoading(false) }, 
        prospectsFilters.storyType, page, rowsPerPage, sort)
      }
    }, [page, sort, rowsPerPage])  

    const onFiltersChange = useCallback(() => {
      if (summaryData?.lapse_runs?.length || summaryData?.ui_runs?.length) {
        setLoading(true)
        const UIFilters = {
          product: prospectsFilters.product,
          cash_value: prospectsFilters.cash_value,
          face_amount: prospectsFilters.face_amount,
          face_amount_prediction: prospectsFilters.face_amount_prediction,
          CSV_LAG: prospectsFilters.surrender_value,
          YRS_REMAIN_SC: prospectsFilters.surrender_year_value,
          IAV_LAG: prospectsFilters.income_value,
          AGENT_STATUS: prospectsFilters.agent_status,
          QTD_LIBR_WITHS_MONTH_CUMSUM_LAG: prospectsFilters.libr_payment,
          RATIO_WITHS_LAG: prospectsFilters.withdraw_activity,
          SUB_ACTIVITY: prospectsFilters.sub_activity,
          gender: prospectsFilters.gender,
          sortKeys: prospectsFilters.age,
          story_lapse: prospectsFilters.story,
          CONTRACT_LOSS: prospectsFilters.contract_loss,
          INCOME_ACOUNT_LOSS: prospectsFilters.income_lost,
          State: prospectsFilters.state,
          RES_STATE_LAG: prospectsFilters.res_state,
          lapse_rank_bin: prospectsFilters.priority,
          RIDER_STATUS_LAG_WITH_FEE: prospectsFilters.libr_status,
          duration_segment: prospectsFilters.Duration_segment,
          mean_premium_term: prospectsFilters.mean_premium_term,
          MARKET_NAME_LAG: prospectsFilters.MARKET_NAME_LAG,
          annualized_premium: prospectsFilters.premium,
          agent_terminated: prospectsFilters.agent_terminated
        }
        const LapseFilters = {
          product: prospectsFilters.product,
          cash_value: prospectsFilters.cash_value,
          face_amount: prospectsFilters.face_amount,
          face_amount_prediction: prospectsFilters.face_amount_prediction,
          CSV_LAG: prospectsFilters.surrender_value,
          YRS_REMAIN_SC: prospectsFilters.surrender_year_value,
          IAV_LAG: prospectsFilters.income_value,
          AGENT_STATUS: prospectsFilters.agent_status,
          QTD_LIBR_WITHS_MONTH_CUMSUM_LAG: prospectsFilters.libr_payment,
          RATIO_WITHS_LAG: prospectsFilters.withdraw_activity,
          SUB_ACTIVITY: prospectsFilters.sub_activity,
          gender: prospectsFilters.gender,
          age_at_issue: prospectsFilters.age,
          story_lapse: prospectsFilters.story,
          CONTRACT_LOSS: prospectsFilters.contract_loss,
          INCOME_ACOUNT_LOSS: prospectsFilters.income_lost,
          State: prospectsFilters.state,
          RES_STATE_LAG: prospectsFilters.res_state,
          lapse_rank_bin: prospectsFilters.priority,
          RIDER_STATUS_LAG_WITH_FEE: prospectsFilters.libr_status,
          duration_segment: prospectsFilters.Duration_segment,
          MARKET_NAME_LAG: prospectsFilters.MARKET_NAME_LAG,
          annualized_premium: prospectsFilters.premium,
          potential_premium_retained: prospectsFilters.mean_premium_term,
          agent_terminated: prospectsFilters.agent_terminated
        }
        let ui = {}
        let lapse = {}
        const activeUIFilters = Object.entries(UIFilters)
          .filter((item) => item[1] !== null)
          .map((item) => ({ [item[0]]: item[1] }))
        const activeLapseFilters = Object.entries(LapseFilters)
          .filter((item) => item[1] !== null)
          .map((item) => ({ [item[0]]: item[1] }))
        if (activeUIFilters.length > 0) {
          Object.assign(ui, ...activeUIFilters)
        }
        if (activeLapseFilters.length > 0) {
          Object.assign(lapse, ...activeLapseFilters)
        }
        if (activeUIFilters.length > 0 || activeLapseFilters.length > 0) {
         dispatch(setQueryFilters({ lapse, ui }))
        }

        ApiService._postProspectsData(
          demo,
          [
            ...summaryData?.lapse_runs.map(({ run_id }) => run_id),
            ...summaryData?.ui_runs.map(({ run_id }) => run_id),
            ...summaryData?.cluster_runs.map(({ run_id }) => run_id)
          ],
          {
          lapse_filters: {
            "precision": [
                {
                    "operator": "precision",
                    "value": "medium"
                }
            ],
            ...lapse
          },
          ui_filters: { ...ui },
          cluster_filters: summaryData?.cluster_runs?.length ? { ...ui } : {}
        }, (response) => {
             annuities ? dispatch(setProspectsDataAE(response.data)) : dispatch(setProspectsData(response.data))
             dispatch(setAvg(response.data['avg_suggested_premium']))
             setLoading(false)
             setClear(false)
        }, (error) => {  
                setLoading(false)
                console.log(error)
        }, prospectsFilters.storyType, 0, rowsPerPage, sort, search)       
      }
    }, [annuities, demo, summaryData, prospectsFilters, search])

    const onFiltersChangeBroker = useCallback(() => {
      setLoading(true)
        const filters = {
          product: prospectsFilters.product,
          face_amount: prospectsFilters.face_amount,
          gender: prospectsFilters.gender,
          age_at_issue: prospectsFilters.age,
          story_lapse: prospectsFilters.story,
          annualized_premium_sensitivity_DML: prospectsFilters.sensitivity,
          duration_segment: prospectsFilters.Duration_segment,
          annualized_premium: prospectsFilters.premium,
          census_household_income_average_weighted: prospectsFilters.per_cap_income,
          annualized_premium_recommended: prospectsFilters.mean_premium_term,
          state: prospectsFilters.state
        }
        dispatch(setQueryFilters({ lapse: filters }))
        const query = buildQueryString(filters)
        ApiService._getRecommendations((response) => {
          dispatch(setProspectsDataBroker(response.data))
          dispatch(setAvg(response.data['avg_suggested_premium']))
          setLoading(false)
          setClear(false)
          dispatch(setFiltersConfig({ filters: query }))
        }, 
        () => {setLoading(false)},
        query, prospectsFilters.storyType, 0, rowsPerPage, sort, search)
    }, [prospectsFilters, search])

  
  useEffect(() => {
      if (!maxRank) {
          ApiService._getPoliciesRanks((response) => {
              setMaxRank(Math.floor(response.data.ranks.max))
          }, (error) => {})
      }
  }, [maxRank])

    
  useEffect(() => {
      return () => {
        setClear(true)
        dispatch(resetFilters())
      }
  }, [dispatch])

    return (
        <Grid container direction="column" justifyContent="center" ref={rootRef}>
            <ProspectsContainer
              projectType={projectType}
              data={prospectsData}
              headers={columns ? Object.keys(columns).filter((key) => columns[key] || (["AUM Upside"].includes(key) && !annuities)) : []}
              page={page}
              clear={clear}
              sort={sort}
              search={search}
              loaded={loaded}
              maxRank={maxRank}
              onChangePage={onPageChange}
              onFiltersChange={isBroker ? onFiltersChangeBroker : onFiltersChange}
              handleChangePage={handleChangePage}
              handleResetFilters={handleResetFilters}
              handleResetUIFilters={handleResetUIFilters}
              handleResetLapseFilters={handleResetLapseFilters}
              handleSort={handleSortClick}
              handleSearch={handleSearch}
              handleSelectPolicy={handleOpenPolicyPopup}
              handleExportAll={() => exporttocsv()}
              handleExport={(id, model, name) => exporttocsv(id, name, model === "ui" ? queryFilters.ui : queryFilters.lapse)}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={handleChangeRowsPerPage}
              divided={false}
              frozenColumns={frozenGridColumns.filter((header) => columns[header === "Upside Story" ? "Story" : header])}
              frozenFields={filteredFrozenFields}
              divider={19}
            />
            <CreateCampaignsDialog open={openCampaignsDialog} onClose={handleCloseCampaignsDialog}/>
            <UnregisteredPopup open={openRegisterDialog} onClose={() => setOpenRegisterDialog(false)}/>
            
            <PolicyPopupBroker
                anchor={rootRef.current}
                open={selectedPolicy !== null && prospectsData?.details?.length > 0}
                onClose={() => {
                  setSelectedPolicy(null)
                  setStrories([])
                }}
                reset={onFiltersChangeBroker}
                onNotesAdded={resetNotes}
                onStatusChange={resetHistory}
                onEdit={handleEditPolicy}
                policy={prospectsData?.details?.find((item) => item.policy === selectedPolicy)}
                notes={notes}
                history={statusHistory}
                stories={stories}
                maxRank={maxRank}
                broker={isBroker}
              />
        </Grid>
    )
}

export default ProspectsAE;