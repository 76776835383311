import { Checkbox, TableCell, TableHead, TableRow } from "@material-ui/core"
import clsx from "clsx";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as Check } from '../../../Assets/icon-checked-flat.svg';
import { ReactComponent as Box } from '../../../Assets/icon-unchecked-flat.svg';
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { selectActiveTab } from "../../../Pages/BookAnalysis/BookAnalysisSlice";

const ProspectsTableHeader = (props) => {
    const { editable, divided, divideIndex, sort, handleSelectAll, onSort, frozenColumns, frozenPositions } = props
    const classes = useStyles()

    const activeTab = useSelector(selectActiveTab)

    const isAE = activeTab === "ProspectsAE"
    const width = window.innerWidth * .09

    return (
    <TableHead className={classes.tableHeader}>
        <TableRow>
            {editable && <TableCell  className={classes.checkContainer}>
                <div className={classes.checkContent}>
                    <Checkbox onChange={(e) => handleSelectAll(e)} checkedIcon={<Check className={classes.checkedIcon}/>} icon={<Box className={classes.checkedIcon}/>}/>
                </div>
            </TableCell>
            }
            {
                props.children.map((item, index) => (
                (divided && index === divideIndex) ? 
                <>
                    <TableCell component="th" scope="row" style={{ maxWidth: width  }} key={`policy-table-header-${item}`}>
                        <div>
                            <p>{item}</p>
                            <ArrowDropDownIcon onClick={() => onSort(item)} style={(sort?.includes("-")) ? { transform: "rotate(180deg)" } : {}}/>
                        </div>
                    </TableCell>
                    <TableCell component="th" scope="row" style={isAE ? { width: 5, padding: 0, background: "#FFFFFF" } : { width: "0.02%", background: "#FFFFFF"}}  key={`policy-table-header-${item}-split`}/>
                </>
                :
                <TableCell
                component="th" 
                scope="row" 
                key={`policy-table-header-${item}`}
                className={clsx(
                    item === "Upside Opportunity" ? classes.tableUpsideHeader : "",
                    frozenColumns?.includes(item === "Story" ? "Upside Story" : item) ? classes.sticky : "",
                    )
                }
                style={index < 4 ? 
                    { 
                        maxWidth: width,
                    } : 
                    {
                        right: frozenColumns?.indexOf(item === "Story" ? "Upside Story" : item) > -1 ? frozenPositions[(frozenColumns?.length-1) - frozenColumns?.indexOf(item === "Story" ? "Upside Story" : item)] : 0,  
                        boxShadow: frozenColumns?.indexOf(item === "Story" ? "Upside Story" : item) > -1 && (frozenColumns?.indexOf(item === "Story" ? "Upside Story" : item) === 0 || frozenColumns?.length === 1) ? "-4px 4px 5px #EEF0F450" : "none", 
                        borderLeft: frozenColumns?.indexOf(item === "Story" ? "Upside Story" : item) > -1 && (frozenColumns?.indexOf(item === "Story" ? "Upside Story" : item) === 0 || frozenColumns?.length === 1) ? "5px solid #FFF" : "none" 
                    }
                }>  
                    <div>
                        <p>{item}</p>
                        <ArrowDropDownIcon onClick={() => onSort(item)} style={(sort?.includes("-")) ? { transform: "rotate(180deg)" } : {}}/>
                    </div>
                </TableCell>
                ))
            }
        </TableRow>
    </TableHead>
    )
}

export default ProspectsTableHeader

