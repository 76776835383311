import { styled } from "@mui/material";
import { Button } from '@material-ui/core';

export const basicButtonStyles = {
  fontFamily: "Roboto, sans-serif!important",
  fontWeight: 400,
  width: '140px',
  height: '40px',
  borderRadius: '8px',
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 12,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  '&:focus': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    opacity: '.7',
  },
};

export const uiViewButtonStyles = {
    ...basicButtonStyles,
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    padding: '26px 0',
    width: '100%',
    maxWidth: '248px',
    height: 'auto',
    borderRadius: '8px',
    backgroundColor: '#0BCB47',
    borderColor: '#FFF',
    color: '#FFF',
    '&:hover': {
        opacity: '0.85',
        backgroundColor: '#0BCB47',
        borderColor: '#FFF',
        boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#239948',
      borderColor: '#FFF',
      boxShadow: 'none',
      color: '#FFFFFF'
    },
    '&.Mui-disabled': {
      backgroundColor: '#0BCB4725',
      borderColor: '#FFF',
      boxShadow: 'none',
      color: '#FFFFFF'
    },
};

export const UIViewButton = styled(Button)(uiViewButtonStyles);


export const HeaderButton =  styled(Button)(() => ({
  ...uiViewButtonStyles,
  width: 168,
  height: 41
}));

export const NextButton =  styled(Button)(() => ({
  ...uiViewButtonStyles,
  width: 250,
  height: 50
}));

export const uiButtonStyles = {
    ...basicButtonStyles,
    width: 'auto',
    padding: '14px 34px',
    maxWidth: '240px',
    height: 'auto',
    borderRadius: '8px',
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    color: '#FFF',
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    textAlign: "center",
    '&:hover': {
      opacity: '0.85',
      backgroundColor: '#363ED3',
      borderColor: '#FFF',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#1D2398',
      borderColor: '#FFF',
      boxShadow: 'none'
    },
    '&.Mui-disabled': {
      backgroundColor: '#1D2398',
      borderColor: '#FFF',
      boxShadow: 'none',
      color: '#FFFFFF'
    },
};

export const cancelButtonStyles = {
  color: "#363ED3",
  background: "#FFFFFF",
  border: "1px solid #363ED3",
  borderRadius: 8,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 168,
  height: 41,
  fontFamily: "Roboto",
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "16px",
  "&:hover": {
      opacity: .9
  }
};

export const UIButton = styled(Button)({
  ...uiButtonStyles
});

export const ClearButton = styled(Button)({
  width: 'auto',
  color: '#363ED3',
  fontFamily: "Roboto",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "12px",
  textAlign: "center",
  textDecoration: "underline",
  textTransform: "capitalize",
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  '&:focus': {
    backgroundColor: 'transparant',
    boxShadow: 'none'
  },
  '& svg': {
    marginLeft: 8
  },
});  

export const AddDataSourceButton = styled(Button)(() => ({
  ...uiButtonStyles,
  padding: 0,
  width: 172,
  height: 40
}));