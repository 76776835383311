import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles(() => ({
    tableBody: {
      "& .MuiTableCell-root": {
        fontFamily: "Roboto",
        fontSize: 13,
        fontWeight: 400,
        lineHeight: "14px",
        color: "#232033",
        borderBottom: "1px solid #EEF0F4",
        textAlign: "left",
      },
      "&:hover .MuiTableCell-root": {
        background: "#fbfaff"
      }
    },
    tableRowBlurry: {
      filter: "blur(3px)",
      "&:hover .MuiTableCell-root": {
        background: "#ffffff"
      }
    },
    tableRowSelected: {
      "& .MuiTableCell-root": {
        background: "#D5D6EE"
      },
      "&:hover .MuiTableCell-root": {
        background: "#D5D6EE"
      }
    },
    checkContainer: {
      padding: 0, 
      width: 32
    },
    checkContent: {
      display: "flex",
      width: 32,
      height: 64,
      alignItems: "center",
      justifyContent: "center"
    },
    checkedIcon: {
      width: 14,
      height: 14,
      marginLeft: 2
    },
    sticky: {
      position: "sticky",
      background: "#FFF",
    },

}));

export default useStyles;