import { Grid, TableCell } from "@material-ui/core"

import { useSelector } from "react-redux"
import TableRowContainer from "../TableRowContainer/TableRowContainer"
import { selectColumns } from "../../../AppSlice"
import PriorityIndicator from "../../../Pages/BookAnalysis/PriorityIndicator/PriorityIndicator"
import RiderStatusIndicator from "../../../Pages/BookAnalysis/RiderStatusIndicator/RiderStatusIndicator"
import PriorityTag from "../../../Pages/BookAnalysis/Prospects/PriorityTag/PriorityTag"
import StoryIcon from "../../Book/StoryIcon/StoryIcon"
import { InfoTooltip } from "../../ui/tooltips"
import moment from "moment"
import { getCountryCurrencySymbol, getPriorityFromRank, nFormatter } from "../../../global/helpers"
import useStyles from "../TableRowContainer/styles"

const ProspectsTableRow = (props) => {
    const { item, model, headers, maxRank, sortKeys, frozenFields, frozenPositions, onRowClick } = props
    const classes = useStyles()
    const currency = getCountryCurrencySymbol()
    const columns = useSelector(selectColumns)

    const renderRow = (rowData, headers, onClick) => {
        return headers
          .filter((header) => columns[header])
          .map((header, index) => {
            const key = sortKeys[header]
            return (
            <TableCell component="th" scope="row" key={index} className={frozenFields.includes(key) ? classes.sticky : ''} style={key === "policy" ? {
              fontWeight: 700,
              lineHeight: "16px",
              color: "#363ED3",
              cursor: "pointer",
              } 
              : frozenFields.includes(key) ? {
                right: frozenPositions[(frozenFields.length-1) - frozenFields.indexOf(key)],  
                boxShadow: (frozenFields.indexOf(key) === 0 || frozenFields.length === 1) ? "-4px 4px 5px #EEF0F450" : "none", 
              }
              : {}
            }
              onClick={() => onClick(rowData)}>
              {
                ["RATIO_WITHS_LAG", "SUB_ACTIVITY", "annualized_premium_sensitivity_NP"].includes(key) ? <PriorityIndicator priority={isNaN(rowData[key]) ? rowData[key]?.toLowerCase() : rowData[key]}/>
                : key === "libr_status" ? <RiderStatusIndicator status={rowData[key]?.toLowerCase()}/>
                : ["INCOME_ACOUNT_LOSS", "CONTRACT_LOSS"].includes(key) ? rowData[key] !== undefined && rowData[key] !== "null" ? `${rowData[key]}%` : "N/A"
                : ["issue_date", "SURRCHRG_END_DATE_LAG", "dob"].includes(key) ? moment(rowData[key]).format("MM/DD/YYYY")
                : ["label", "annualized_premium_sensitivity_DML"].includes(key) ? rowData[key] ? <PriorityTag value={rowData[key]}/> : "N/A"
                : ["rank"].includes(key) ? rowData[key] ? <PriorityTag value={getPriorityFromRank(rowData[key], maxRank)}/> : "N/A"
                : ["policy", "servicing_agent_deid", "AGENT_ID_LAG"].includes(key) || key === "" ? rowData[key]?.length > 10 ? rowData[key].slice(0, 9)+"..." : rowData[key]
                : ["age_at_issue", "age", "YRS_REMAIN_SC", "lapse_rank", "zip_code"].includes(key) || key === "" ? rowData[key]? rowData[key] : "N/A" 
                : ["story", "story_lapse"].includes(key) ? rowData[key]? 
                <InfoTooltip title={rowData[key]}>
                  <Grid item style={{ display: "flex"}} alignItems="center">
                    <div style={{ minWidth: 20, marginRight: 8 }}><StoryIcon storyType={model}/></div>
                    <div>{rowData[key]?.length > 25 ? rowData[key].slice(0, 24).trim()+"..." : rowData[key]}</div>
                  </Grid>
                </InfoTooltip>
                 : "N/A" 
                : typeof(rowData[key]) === "boolean" ? `${rowData[key]}`
                : !isNaN(rowData[key]) ? rowData[key] ? rowData[key] > 9999 ? nFormatter(rowData[key], 2, currency) : `$${Math.floor(rowData[key])}` : "N/A" 
                : rowData[key] ? rowData[key] : "N/A" 
              }
            </TableCell>
          )
        })
    }

    return (
        <TableRowContainer {...props}>
            {renderRow(item, headers, onRowClick)}
        </TableRowContainer>
    )

}

export default ProspectsTableRow