import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import {basicButtonStyles} from "../../Components/ui/buttons";

// Components
import Opportunities from "./Opportunities/Opportunities";
import ProspectsAE from "./Prospects/ProspectsAE";

// Redux
import { resetFilters, selectProductsData, selectActiveTab, setActiveTab, selectHeaders, resetImpactsData, setGroupByProject, selectDefaultProspectsColumn, setProjectIds, setProjectLabels, setRunsMeta, setStoriesIsLoading, selectIds, selectProspectsData } from "./BookAnalysisSlice.js";
import { selectUserCompany, selectUserPermissions, setColumns } from "../../AppSlice";

// Services
import { amplitudeService } from "../../global/helpers"
import Impacts from "./Impacts/Impacts";
import HeaderProspects from "../../Components/Headers/HeaderProspects";
import HeaderContainer from "../../Components/Headers/HeaderContainer/HeaderContainer.jsx";
import { User } from "../../global/user.jsx";
import Showcase from "./Showcase/Showcase.jsx";
import appApiService from "../../AppService.js";

const useStyles = makeStyles(() => ({
    tab: {
        background: '#FFFFFF',
        border: '1px solid #EBEBEB',
        borderBottom: 'none',
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        width: 235,
        height: 38,
        textAlign: "center",
        paddingTop: 12,
        color: '#6A6C71',
        marginRight: 5,
        cursor: "pointer",
        "& .MuiTypography-root": {
            fontFamily: "Roboto",
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "19px"
        }
    },
    tabActive: {
        color: '#363ED3',
        height: 39,
        position: 'relative',
        bottom: -1,
        zIndex: 900
    },
    headerContainer: {
        paddingBottom: 21
    },
    portfolioAnalysis: {
        paddingRight: 24
    },
    portfolioAnalysisTitle: {
        fontFamily: "Roboto",
        fontSize: 24,
        fontWeight: 400,
        lineHeight: "24px",
        paddingBottom: 12
    },
    portfolioAnalysisText: {
        fontFamily: "Roboto",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "16px"
    },
    portfolioAnalysisValue: {
        fontFamily: "Lato!important",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "17px",
        color: '#232033'
    },
    predictionContainer: {
        borderColor: "#0BCB47",
        maxWidth: 411,    
        "& path": {
            fill: '#0BCB47',
            stroke: '#0BCB47'
        },
        "@media (max-width:1310px)": {
            maxWidth: 347  
        }
    },
    addDataCotainer: {
        display: "flex",
        maxWidth: 178, 
        "@media (max-width:1380px)": {
            maxWidth: 120  
        }
    },
    demoText: {
        fontFamily: "Roboto",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "20px",
        color: '#515151', 
        paddingBottom: 34,       
        "& span": {
            color: '#363ED3', 
            textDecoration: "underline",
            cursor: "pointer", 
            fontWeight: 500
        }
        
    },
    main: {
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: 6,
        borderTopLeftRadius: 0,
        padding: "39px 52px 48px 52px"
    }

}));

export const uiButtonStyles = {
    ...basicButtonStyles,
    width: 'auto',
    padding: '14px 34px',
    maxWidth: '240px',
    height: 'auto',
    borderRadius: '8px',
    backgroundColor: '#363ED3',
    borderColor: '#FFF',
    color: '#FFF',
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "16px",
    textAlign: "center",
    '&:hover': {
      opacity: '0.9',
      backgroundColor: '#363ED3',
      borderColor: '#FFF',
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      backgroundColor: '#D8D8D8'
    },
  };

const defaultTabs = [
  'Impact on Premium',
  'Immediate Opportunities',
  //'Prospects',
];

const AppService = new appApiService()

const BookAnalysis = ({ demo = false }) => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const headersData = useSelector(selectProductsData)
    const headerProspects = useSelector(selectHeaders)
    const activeTab = useSelector(selectActiveTab)
    const permissions = useSelector(selectUserPermissions)
    const defaultColumns = useSelector(selectDefaultProspectsColumn)
    const projectIds = useSelector(selectIds)
    const user = useSelector(selectUserCompany)
    const prospectsData = useSelector(selectProspectsData)
    const rootRef = useRef(null)
    const [ tabs, setTabs ] = useState(defaultTabs)
    const isAnnuities = localStorage.getItem("accountType") === "annuities"
    const isBroker = localStorage.getItem("accountType") === "broker" && User().isHideOnProd

    const projectType = (
        (headersData?.ui_runs?.length > 0 && headersData?.ui_runs?.find(({ config }) => config?.product_type?.toLowerCase() === "annuities") !== undefined)
        || (headersData?.lapse_runs?.length > 0 && headersData?.lapse_runs?.find(({ config }) => config?.product_type?.toLowerCase() === "annuities") !== undefined)
        ? "annuities" : "regular"
    )

    const registrationRedirect = () => {
      localStorage.removeItem("token")
      localStorage.removeItem("refresh")
      localStorage.removeItem("userName")
      history.push("/registration")
    }
    
    const handleChangeTab = (item) => {
        if (item === "Impacts on Assets" || item === "Impacts on Premium") {
            dispatch(resetImpactsData())
        }
        if (item !== "Prospects" && item !== "ProspectsAE") {
            dispatch(resetFilters()) 
        }
        dispatch(setActiveTab(item))
        amplitudeService._throwEvent("BOOK_TAB_CHANGE", {'tab': item})
      }

    const getStepComponent = () => {
        switch (activeTab) {
            case 'Immediate Opportunities':
                return <Opportunities outSideRef={rootRef} projectType={projectType}/>
            case 'Recommended Campaigns':
                return <Opportunities outSideRef={rootRef} projectType={projectType}/>
            case 'Prospects':
                return <ProspectsAE handleLogout={registrationRedirect} projectType={projectType}/>
            case 'ProspectsAE':
                return <ProspectsAE handleLogout={registrationRedirect} projectType={projectType} annuities/>
            case 'Policyholders':
                return <ProspectsAE handleLogout={registrationRedirect} projectType={projectType}/>
           case 'Impacts on Assets':
                return <Impacts/>
           case 'Impact on Premium':
                return <Impacts/>
           case 'Data Showcase':
                return <Showcase/>
        
            default:
                break;
        }
    }

    useEffect(() => {
        if (isBroker) {
            dispatch(setGroupByProject("All Book"))
        }
    }, [isBroker])

    

    useEffect(() => {
        if (isBroker) {
                dispatch(setStoriesIsLoading(true))
                AppService._getRuns(
                    localStorage.getItem("companyID"),
                    (res) => {
                        if (res.data?.count !== 0 || user === "platform-anonym") {
                            if (activeTab === "Policyholders") {
                                const columnsConfig = res.data?.projects[0]?.runs[0]?.columns_to_display?.columns
                                const tmp = {}
                                if (columnsConfig?.length > 0) {
                                    Object.assign(tmp, ...Object.keys(defaultColumns)
                                        .map((key) => ({[key]: columnsConfig.includes(key) || ["AUM Upside", "Premium Upside"].includes(key)})))
                                    dispatch(setColumns(
                                        tmp
                                    ))
                                } else {
                                    Object.assign(tmp, ...Object.keys(defaultColumns)
                                        .map((key) => ({[key]: defaultColumns[key]})))
                                    dispatch(setColumns(
                                        tmp
                                    ))
                                }
                            }
                            
                            if (!projectIds?.length) {
                                const meta = []
                                const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                                    meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "", runs: runs.filter(({ status }) => status?.toLowerCase() !== "failed")})
                                    return project_id
                                })
                                dispatch(setProjectIds(ids))
                                dispatch(setProjectLabels(meta))
                                dispatch(setRunsMeta(res.data))
                            }
                        } else { 
                            // Load demo data
                            AppService._getDemoRunsData((res) => {
                                if (activeTab === "Showcase") {
                                    history.push("/book?demo") 
                                }
                                if (activeTab === "Policyholders") {
                                    const columnsConfig = res.data?.projects[0]?.runs[0]?.columns_to_display?.columns
                                    const tmp = {}
                                    if (columnsConfig?.length > 0) {
                                        Object.assign(tmp, ...Object.keys(defaultColumns)
                                            .map((key) => ({[key]: columnsConfig.includes(key) || ["AUM Upside", "Premium Upside"].includes(key)})))
                                        dispatch(setColumns(
                                            tmp
                                        ))
                                    } else {
                                        Object.assign(tmp, ...Object.keys(defaultColumns)
                                            .map((key) => ({[key]: defaultColumns[key]})))
                                        dispatch(setColumns(
                                            tmp
                                        ))
                                    }
                                }   
                                
                                if (!projectIds?.length) {
                                    const meta = []
                                    const ids = res.data.projects.map(({ project_id, project_name, runs }) => {
                                        meta.push({ title: project_name, id: project_id, date: runs?.length > 0 ? runs[runs.length-1].created_at : "", runs: runs.filter(({ status }) => status?.toLowerCase() !== "failed")})
                                        return project_id
                                    })
                                    dispatch(setProjectIds(ids))
                                    dispatch(setProjectLabels(meta))
                                    dispatch(setRunsMeta(res.data))
                                }
                            },
                            (error) => { console.log(error) })
      
                        }
      
                    }, 
                (error) => { dispatch(setStoriesIsLoading(false)); console.log(error);}
                )
      
            
        }
    }, [dispatch, projectIds, prospectsData])

    useEffect(() => {
        return () => {
            dispatch(resetImpactsData())
       }
    }, [dispatch])

    useEffect(() => {
        if (isAnnuities) {
           setTabs([
               'Impacts on Assets',
               'Immediate Opportunities',
               //'ProspectsAE'
             ])
          dispatch(setActiveTab('Impacts on Assets'))  
       }
       if (isBroker) {
          setTabs([
              'Data Showcase',
              'Policyholders',
              'Recommended Campaigns'
            ])
         dispatch(setActiveTab('Data Showcase'))  
      }
        return () => {
            setTabs(defaultTabs)
            dispatch(setActiveTab('Impact on Premium'))  
       }
    }, [permissions])



    return (
        <Grid container direction="column" ref={rootRef}> 
            <HeaderContainer title={isBroker ? "Book Analysis" : "Portfolio Optimizer"}>
                <HeaderProspects data={headerProspects} titles={[isBroker ? "" : "Projects", "Prediction Start Date", "Prospects", `${isAnnuities ? "AUM Upside" : "Premium Upside"}`]} isBook={isBroker}/>
            </HeaderContainer>
            {demo && 
            <Grid container justifyContent="flex-start">
                <Typography className={classes.demoText}>
                    The following analysis is based on a demo data. Please <span onClick={() => history.push("/registration")}>create account</span> to see your real results.
                </Typography>
            </Grid>
            }
            <Grid container justifyContent="flex-start">
                {
                    tabs.map((item) => (
                        <Grid item className={clsx(classes.tab, activeTab === item ? classes.tabActive : '')} onClick={() => handleChangeTab(item)}>
                            <Typography>{item?.replace("AE", "")}</Typography>
                        </Grid>                        
                    ))
                }
            </Grid>
            <Grid className={activeTab !== "Recommended Campaigns" && activeTab !== "Immediate Opportunities" && activeTab !== `${isAnnuities ? "Impacts on Assets" : "Impact on Premium"}` ? classes.main : ""} style={{ maxWidth: window.innerWidth-143 }}>            
                {
                    getStepComponent()
                }
            </Grid>

        </Grid>
    )
}

export default BookAnalysis