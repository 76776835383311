import { Button, CircularProgress, Grid, MenuItem, Popover, TableBody, TableContainer, Typography } from "@material-ui/core"
import { Select } from "@mui/material"
import React, { useState, useRef } from "react"
import StoryIcon from "../../Book/StoryIcon/StoryIcon"
import PriorityIndicator from "../../../Pages/BookAnalysis/PriorityIndicator/PriorityIndicator"
import BookTablePagination from "../../Tables/BookTablePagination/BookTablePagination"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useStyles } from "./styles"
import { useDispatch, useSelector } from "react-redux"
import { selectUserSubscriptions } from "../../../AppSlice"
import { User } from "../../../global/user"
import ProspectsTableHeader from "../ProspectsTableHeader/ProspectsTableHeader"
import { selectSelectedPolicies, setSelectedPolicies } from "../../../Pages/BookAnalysis/BookAnalysisSlice"
import { withProps } from "../../../global/hoc"

const ProspectsTableContainer = ({
    editable,
    data,
    page,
    headers,
    sort,
    sortKeys,
    maxRank,
    handleSort,
    handleChangePage,
    handleChangeRows,
    handleSelectPolicy,
    handleExportAll,
    handleExport,
    loaded,
    divided,
    divider,
    frozenFields,
    frozenColumns,
    frozenPositions,
    rowsPerPage,
    model = null,
    filters = null,
    exportOptions = [], 
    paginated = false, 
    children
}) => {
    const classes = useStyles()
    const exportRef = useRef()
    const dispatch = useDispatch()
    const [openExportOptions, setOpenExportOptions] = useState(false)
    const subscriptions = useSelector(selectUserSubscriptions)
    const selectedPolicies = useSelector(selectSelectedPolicies)

    const isBroker = (localStorage.getItem("accountType") === "broker" && User().isHideOnProd) || User().isBroker;
    const isFreeSubcriptionPlan = isBroker && !subscriptions.find(({ name }) => name === "basic")
    const gridData = paginated ? data?.prospects?.slice(rowsPerPage*page, (page*rowsPerPage)+rowsPerPage) : data?.prospects
   
    const handlePolicyCheckAll = (e) => {
        if (e.target.checked) {
            dispatch(setSelectedPolicies(
                {
                    "all": true
                }
            ))
        } else {
            dispatch(setSelectedPolicies({}))
        }
    }

    const handlePolicyCheck = (e, item) => {
        dispatch(setSelectedPolicies({
            ...Object.assign({}, ...Object.keys(selectedPolicies).filter((key) => key !== "all").map(((key) => ({[key]: selectedPolicies[key]})))),
            [item]: e.target.checked
        }))
    }

    const onRowClick = (row, index) => { 
        if (!(index > 4 && isFreeSubcriptionPlan)) {
            handleSelectPolicy(row[row["model_type"] === "cluster" ? "ID" : "policy"], row["id"], row["story_to_show"] ? row["story_to_show"] : "upsell"); 
        }
    }

    return (
        <Grid container>
            <TableContainer>
                <ProspectsTableHeader
                    editable={editable && !isFreeSubcriptionPlan}
                    divided={divided}
                    divideIndex={divider}
                    sort={sort}
                    frozenColumns={frozenColumns}
                    frozenPositions={frozenPositions}    
                    onSort={isFreeSubcriptionPlan ? () => {} : handleSort}       
                    handleSelectAll={handlePolicyCheckAll}       
                >
                    {headers}
                </ProspectsTableHeader>
                {
                loaded ? 
                <TableBody>
                    {
                        gridData
                        ?.map((item, index) => (
                            withProps(
                                children, {
                                    item,
                                    model: model ? model : item["model_type"] ,
                                    maxRank,
                                    headers,
                                    page,
                                    index,
                                    editable,
                                    sortKeys,
                                    frozenFields,
                                    frozenPositions,
                                    selectedPolicies,
                                    onRowClick,
                                    handlePolicyCheck
                            })
                        ))
                    }
                    {}
                </TableBody>
                :
                <Grid container justifyContent="center" alignContent="center" style={{minHeight: 600}}>
                    <CircularProgress style={{color: "#000",width:96,height:96}}/>
                </Grid>
                }
            </TableContainer>

            <Grid container  justifyContent="space-between" alignItems="center">
                <div className={classes.gridItem}>
                    <Select value={rowsPerPage} className={classes.showOnPageSelect} prefix="Show on page" onChange={(e) => handleChangeRows(e.target.value)}
                    style={{width: '76px'}}>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                    {!isFreeSubcriptionPlan && <div className={classes.exportToCSVButtonContainer} ref={exportRef}>
                      <Button
                        variant="outlined"
                        disableElevation={true}
                        disableFocusRipple={true}
                        className={classes.exportToCSVButton}
                        endIcon={<ArrowRightAltIcon className={classes.exportToCSVIcon} />}
                        onClick={() => setOpenExportOptions(true)}
                      >
                        Export to CSV
                      </Button>
                    </div>}
                </div>
            {filters && <Grid item className={classes.typeFilterContainer} alignItems="center">
                {
                (!filters.storyType || filters.storyType.includes("lapse")) 
                &&
                <div className={classes.modelIndicator} 
                >
                    <StoryIcon storyType="lapse"/>
                    <Typography style={{ marginRight: 24 }}>Retention</Typography>
                </div>
                }
                {
                (!filters.storyType || filters.storyType.includes("ui"))
                &&
                <div className={classes.modelIndicator} 
                >
                    <StoryIcon storyType="ui"/>
                    <Typography style={{ marginRight: 24 }}>New Business</Typography>
                </div>
                }
                {
                <div style={{ display: "none" }}>
                    <PriorityIndicator priority="high"/>
                    <Typography style={{ marginRight: 24 }}>High</Typography>
                </div>
                }
                {
                <div style={{ display: "none" }}>
                    <PriorityIndicator priority="mid"/>
                    <Typography style={{ marginRight: 24 }}>Medium</Typography>
                </div>
                }
                {
                <div style={{ display: "none" }}>
                    <PriorityIndicator priority="low"/>
                    <Typography>Maintained</Typography>
                </div>
                }
            </Grid>}
            <Grid item>
            {!isFreeSubcriptionPlan && data?.prospects?.length > 0 && <BookTablePagination
                component="div"
                count={data.count}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10]}
            />
            }
            </Grid>
            </Grid>
            <Popover
            anchorEl={exportRef.current}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={openExportOptions && !isFreeSubcriptionPlan}
            onClose={() => setOpenExportOptions(false)}>
                {exportOptions.length === 0 ? <Grid container className={classes.actionsPopoverContainer}>
                    <Grid item className={classes.actionButtonContainer} onClick={handleExportAll}>
                        <Button className={classes.actionButton}>
                            Export All
                        </Button>
                    </Grid>
                    <Grid item className={classes.actionButtonContainer} onClick={handleExport}>
                        <Button disabled={!data.count} className={classes.actionButton}>
                            Export Filtered
                        </Button>
                    </Grid>
                </Grid> : 
                <Grid container className={classes.actionsPopoverContainerXL}>
                    {
                        exportOptions?.map(({ run_id, name, model }) => (
                            <Grid item className={classes.actionButtonContainer} onClick={() => handleExport(run_id, model, name)}>
                                <Button className={classes.actionButton}>
                                    Export {name}
                                </Button>
                            </Grid>
                        ))
                    }
                </Grid>              
                }
            </Popover> 
    </Grid>
    )
}

export default ProspectsTableContainer