import { makeStyles } from "@material-ui/styles"

export const useStyles = makeStyles(() => ({
  tableHeader: {
    "& .MuiTableCell-root": {
      fontFamily: "Roboto",
      fontSize: 13,
      fontWeight: 700,
      lineHeight: "16px",
      color: "#232033",
      background: "#EEF0F4",
      borderBottom: "none",
      verticalAlign: "top",
      width: "10.5%",
      "& div": {
        display: "flex",
      },
      "& p": {
        paddingRight: 5,
        margin: 0
      },
      "& svg": {
        cursor: "pointer",
        marginTop: -5
      }
    },
    '& th:first-child': {
        borderRadius: '6px 0 0 6px'
    },
    '& th:last-child': {
        borderRadius: '0 6px 6px 0'
    },
  },
  tableUpsideHeader: {
    minWidth: 220
  },
  sticky: {
    position: "sticky",
  },
  checkContainer: {
    padding: 0, 
    width: 32
  },
  checkContent: {
    display: "flex",
    width: 32,
    height: 64,
    alignItems: "center",
    justifyContent: "center"
  },
  checkedIcon: {
    width: 14,
    height: 14,
    marginLeft: 2
  },
}));