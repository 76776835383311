import { TableCell } from "@material-ui/core"
import TableRowContainer from "../TableRowContainer/TableRowContainer"
import moment from "moment"

const LeadsTableRow = (props) => {
    const { item, headers } = props

    const renderRow = (rowData) => {
      return Object.keys(rowData)
      .map((key) => (
        <TableCell component="th" scope="row">
          {
            ["created_at", "updated_at"].includes(key) ? rowData[key] ? moment(rowData[key]).format("MM/DD/YYYY") : "N/A"
            : ["isagent"].includes(key) ? rowData[key].toString()
            : rowData[key] ? rowData[key] : "N/A"
          }
        </TableCell>
      ))
    }

    return (
        <TableRowContainer {...props}>
            {renderRow(item, headers)}
        </TableRowContainer>
    )

}

export default LeadsTableRow